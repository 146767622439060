import * as React from 'react';
import SearchDisplayComonent from '../MainComponents/SearchDisplayComponent.tsx';
import { Box, Button, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export default function DesktopHome() {

  const spotTypes = ["Silver","Gold","Platinum"];
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [value, setValue] = React.useState(0);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };


  React.useEffect(() => {
    // Add an event listener for the "resize" event
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); // Empty dependency array to run the effect only once

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [lowerCat,setLowerCat] = React.useState("Junk Silver")

  return (
    <div className='defPageMarg'>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Gold Guys Bullion</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Gold Guys Bullio"/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
        </Helmet>
    <SearchDisplayComonent categoryDisplay="New Arrivals" limitRes={true}/>
    <hr/>
    {screenWidth > 500 ? <div>
    <h3 style={{marginLeft:20}}>
      More Categories
    </h3>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',margin:'0 20px' }}>
        <Tabs value={value} onChange={handleChange} style={{overflow:'visible'}}>
          <Tab label="Junk Silver" className='goldBack' onClick={()=>{setValue(0);setLowerCat("Junk Silver")}}/>
          <Tab label="US Silver Coins" className='goldBack' onClick={()=>{setValue(1); setLowerCat("US Silver Coins")}}/>
          <Tab label="Silver Bars" className='goldBack' onClick={()=>{setValue(2);setLowerCat("Silver Bars")}}/>
          <Tab label="Fractional Gold Bars" className='goldBack' onClick={()=>{setValue(3);setLowerCat("Fractional Gold Bars")}}/>
        </Tabs>
      </Box>
    </Box>
    </div>
    : <>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider',margin:'0 20px' }}>
        <Tabs value={value} onChange={handleChange} style={{overflow:'visible',justifyContent:'space-evenly !important'}}>
          <Tab label="Junk Silver" className='goldBack' onClick={()=>{setValue(0);setLowerCat("Junk Silver")}}/>
          <Tab label="US Silver Coins" className='goldBack' onClick={()=>{setValue(1); setLowerCat("US Silver Coins")}}/>
        </Tabs>
      </Box>
    </Box>
    </>}
        <hr/>
        <SearchDisplayComonent categoryDisplay={lowerCat} limitRes={true}/>
        {/*<Link to='/Junk-Silver' state={{ breadCrumb: '/Silver/Junk-Silver'}} className="linkstyle goldBack">
        <div style={{display:'flex',justifyContent:'center'}} >
        <Button variant="contained" className='goldButton'>View More</Button>
        </div>
    </Link>*/}
    </div>
  );
}