import * as React from 'react';
import {Helmet} from "react-helmet";
import PLPCard from '../SmallComponents/PLPCard.tsx';
import { CardContent, CardMedia, CircularProgress, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
const algoliasearch = require("algoliasearch");
const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_APP_SEARCH_KEY);
export default function ReccomendDisplay(props){
    const [indexV, setIndexV] = React.useState('most_popular');
    var index = client.initIndex(indexV);
    const location = useLocation()
    const[itemList,setItemList] = React.useState([])
    const [isChecked, setChecked] = React.useState(false);
    const [cat, setCat] = React.useState('');
    const [cat2, setCat2] = React.useState('');
    const [cat3, setCat3] = React.useState('');
    const [searchVal, setSearchVal] = React.useState('');
    const [headerBackgroundStyle, setHeaderBackground] = React.useState('');
    const [customHeaderBR, setcustomHeaderBR] = React.useState('');
    const [isLoadingSearch, setLoadingSearch] = React.useState(true);
    const [resultNumber, setResultNumber] = React.useState(0);
    const [breadSearchComplete, setBreadSearchComplete] = React.useState(false);
    
    const [pathParams, setPathParams] = React.useState([]);
    const nav = useNavigate()

    const components: JSX.Element[] = []; // Specify the type as JSX.Element[]
    const numComponentsToRender = 10;
    var imageString = ""
    var itemName = ""
    var imageCat = props.categoryDisplay;
    
    const [sort, setSort] = React.useState('');
    const [showSort, setShowSort] = React.useState(false);
    function getUrlParamValue(paramName) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const paramValue = urlSearchParams.get(paramName);
        return paramValue;
      }

    function fetchPath(url){
      if(url.toLowerCase().includes("silver")){
        return ["Silver"]
      }
      if(url.toLowerCase().includes("gold")){
        return ["Gold"]
      }
      if(url.toLowerCase().includes("platinum")){
        return ["Platinum"]
      }
      if(url.toLowerCase().includes("deals")){
        return ["Deals"]
      }
      return []
    }
      
    React.useEffect(()=>{

        const pathname = props.categoryList//"/Silver/Silver-Coins/U.S.-Silver-Coins".replaceAll("-", " ")//url.pathname.replaceAll("-", " ")
        //create url structure using props
        

          const segments = pathname.split('/');
        
          // Filter out empty segments and return the path parameters
          const pathParameters = segments
          .filter(segment => segment !== '')
          .map(decodeURIComponent);
          setPathParams(pathParameters)
        

        setcustomHeaderBR("")
        if(String(pathname).toLowerCase().includes("silver")){
          setHeaderBackground("fgrey")
        } else 
        if(String(pathname).toLowerCase().includes("gold")){
          setHeaderBackground("goldBack")
        } else
        if(String(pathname).toLowerCase().includes("platinum")){
          setHeaderBackground("platBack")
        } else{
          setcustomHeaderBR("")
          setHeaderBackground("")
        }
        
        
        //Get searchterm
        const searchParamValue = getUrlParamValue('search');
        if(searchParamValue != undefined || searchParamValue != "" && searchParamValue != null){
            setSearchVal(searchParamValue)
            setPathParams([])
        }else{
          setSearchVal("")
        }
        setBreadSearchComplete(true)

    },[props.categoryList])

    const handleChange = (event) => {
      setIndexV(event.target.value);
    };
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
      };
      React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [location])

      function customComparisonMostPopular(a, b) {
        // Example: Sort by a custom attribute 'customAttribute'
        if (parseFloat(a.stock) > parseFloat(b.stock)) {
          return -1;
        }
        if (parseFloat(a.stock) < parseFloat(b.stock)) {
          return 1;
        }
        return 0;
      }

      function customCparison_item_asc(a, b) {
        // Example: Sort by a custom attribute 'customAttribute'
        let priceA = a?.price ?? 0 
        let priceBookABook = a?.priceBookRate
        let priceBookA = 0 
        if(priceBookABook.length > 0){
          priceBookA = priceBookABook[0].pricebook_rate
        }else{
          priceBookA = priceBookABook
        }
        let atotal = priceA + priceBookA
        let silverCont = a.customField.cf_silver_content
        atotal = parseFloat(atotal) * parseFloat(silverCont)

        let priceB = b?.price ?? 0 
        let priceBookBBook = b?.priceBookRate ?? 0 
        let priceBookB = 0 
        if(priceBookBBook.length > 0){
          priceBookB = priceBookBBook[0].pricebook_rate
        }else{
          priceBookB = priceBookBBook
        }
        let btotal = priceB + priceBookB
        let silverContB = b.customField.cf_silver_content
        btotal = parseFloat(btotal) * parseFloat(silverContB)

        if (parseFloat(atotal) < parseFloat(btotal)) {
          return -1;
        }
        if (parseFloat(atotal) > parseFloat(btotal)) {
          return 1;
        }
        return 0;
      }

      function customCparison_item_desc(a, b) {
        // Example: Sort by a custom attribute 'customAttribute'
        let priceA = a?.price ?? 0 
        let priceBookABook = a?.priceBookRate
        let priceBookA = 0 
        if(priceBookABook.length > 0){
          priceBookA = priceBookABook[0].pricebook_rate
        }else{
          priceBookA = priceBookABook
        }
        let atotal = priceA + priceBookA
        let silverCont = a.customField.cf_silver_content
        atotal = parseFloat(atotal) * parseFloat(silverCont)

        let priceB = b?.price ?? 0 
        let priceBookBBook = b?.priceBookRate ?? 0 
        let priceBookB = 0 
        if(priceBookBBook.length > 0){
          priceBookB = priceBookBBook[0].pricebook_rate
        }else{
          priceBookB = priceBookBBook
        }
        let btotal = priceB + priceBookB
        let silverContB = b.customField.cf_silver_content
        btotal = parseFloat(btotal) * parseFloat(silverContB)

        if (parseFloat(atotal) > parseFloat(btotal)) {
          return -1;
        }
        if (parseFloat(atotal) < parseFloat(btotal)) {
          return 1;
        }
        return 0;
      }

    React.useEffect(()=>{
        var filter = ""
        var limit = 100
        if(props != undefined && props.limitRes == true){
          limit = 8
          setShowSort(false)
        }else{
          setShowSort(true)
        }


        if(pathParams.length == 0 && imageCat == ""){
          return(
            <div style={{textAlign:'center',margin:50,height:300}}>
              <CircularProgress/>
            </div>
          )
        }

        if(pathParams.length == 0 && location.pathname != "/" && imageCat != "Search"){
          return
        }

        if(pathParams.length > 0){ 
            //if(pathParams.includes("silver"))
            for(let i = 0; i < pathParams.length; i++ ){
                if(i == 0 ){
                    filter = 'category:' + pathParams[i]
                }
                if(i == 1){
                    filter = filter + ' AND category2: "' + pathParams[i] +'"'
                }
                if(i == 2){
                    filter = filter + ' AND category3: "' + pathParams[i] +'"'
                }
                if(pathParams.includes("Deals")){
                    filter ='dealitem: true'
                }
            }
            if(imageCat == "US Silver Coins"){
              filter = filter + 'category3:"U.S. Silver Coins"'
              limit = 4
            }
            if(imageCat == "New Arrivals"){
              setShowSort(false)
              index = client.initIndex("date_desc");
              const exfilter = `NOT category:Collectibles AND NOT category:Supplies`;
              filter = exfilter
              limit = 30
            }
            if(imageCat == "supplies" || imageCat ==  "Collectibles" || pathParams[0] ==  "collectibles"|| pathParams[0] ==  "supplies"){
              setShowSort(false)
            }
            console.log("APPLYINGTHISFILTER: " ,filter)
            let hit1 = []
            index
            .search(searchVal,{filters:filter,hitsPerPage:4})
            .then(({ hits }) => {
              console.log("UPPER")
              console.log(hits)
              hit1 = hits
              if(itemList.length < 5){
                let filter = ""
                for(let i = 0; i < pathParams.length; i++ ){
                  if(i == 0 ){
                      filter = 'category:' + pathParams[i]
                  }
                  if(i == 1){
                    filter = filter + ' AND category2: "' + pathParams[i] +'"'
                  }
              }
              let needed = 4-hits.length
              index
                    .search(searchVal,{filters:filter,hitsPerPage:needed})
                    .then(({ hits }) => {
                      console.log("Lower")
                      console.log(hits)
                      let newList = hit1
                      for(let i = 0 ; i < hits.length; i++){
                        let item:any = hits[i]
                        newList.push(item)
                      }
                      console.log(newList)
                      setItemList([...newList])
                    })
                    setItemList(hits)

              }else{
              setItemList(hits)
              }
            })
            setLoadingSearch(false)

            }
        

    
    },[pathParams,indexV,location,searchVal,imageCat])

    function capitalizeFirstLetter(str) {
        str = str.replaceAll("-" , " ")
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

    let coincats = [{name:"U.S. Silver Coins",bread:'/Silver/Silver-Coins/U.S.-Silver-Coins',image:'/static/images/US Silver Coins.png'}, 
    {name:"Australian Silver Coins",bread:'/Silver/Silver-Coins/Australian-Silver-Coins',image:'/static/images/Australian Silver Coins (1).png'}, 
    {name:"Austrian Silver Coins",bread:'/Silver/Silver-Coins/Austrian-Silver-Coins',image:'/static/images/Austrain Silver Coins.png'}, 
    {name:"British Silver Coins",bread:'/Silver/Silver-Coins/British-Silver-Coins',image:'/static/images/British Silver Coins (1).png'},{name:"Canadian Silver Coins",bread:'/Silver/Silver-Coins/Canadian-Silver-Coins',image:'/static/images/Canadian Silver Coins.png'},{name:"Chinese Silver Coins",bread:'/Silver/Silver-Coins/Chinese-Silver-Coins',image:'/static/images/Chinese Silver Coins (1).png'},{name:"Mexican Silver Coins",bread:'/Silver/Silver-Coins/Mexican-Silver-Coins'},{name:"Niue Silver Coins",bread:'/Silver/Silver-Coins/Niue-Silver-Coins',image:'/static/images/Niue Silver Coins (1).png'},{name:"African Silver Coins",bread:'/Silver/Silver-Coins/African-Silver-Coins',image:'/static/images/African Silver Coins (1).png'},{name:"Fractional Silver Coins",bread:'/Silver/Silver-Coins/Fractional-Silver-Coins'},{name:"Other Silver Coins",bread:'/Silver/Silver-Coins/Other-Silver-Coins',image:'/static/images/Other Silver Coins (1).png'}]
    let silverSubCats = [{name:"Silver Coins",bread:'/Silver/Silver-Coins',image:'/static/images/Silver Coins (1).png'}, {name:"Silver Rounds",bread:'/Silver/Silver-Rounds',image:'/static/images/Silver Rounds (1).png'}, 
    {name:"Silver Bars",bread:'/Silver/Silver-Bars',image:'/static/images/Silver Bars (1).png'}, {name:"Fractional Silver",bread:'/Silver/Fractional-Silver',image:'/static/images/Fractional Silver (1).png'}, {name:"Junk Silver",bread:'/Silver/Junk-Silver',image:'/static/images/Junk Silver (1).png'}, 
    ]
    let goldSubCats = [{name:"Gold Coins",bread:'/Gold/Gold-Coins',image:'/static/images/Gold Coins (1).png'}, {name:"Gold Bars",bread:'/Gold/Gold-Bars',image:'/static/images/Gold Bars (1).png'}, 
    ]
    let silverBarSubcats = [{name:"1 oz Silver Bars",bread:'/Silver/Silver-Bars/1-oz-Silver-Bars',image:'/static/images/1 oz Silver Bars (1).png'},{name:"5 oz Silver Bars",bread:'/Silver/Silver-Bars/5-oz-Silver-Bars',image:'/static/images/5 oz Silver Bars (1).png'},{name:"10 oz Silver Bars",bread:'/Silver/Silver-Bars/10-oz-Silver-Bars',image:'/static/images/10 oz Silver Bars (1).png'},
    {name:"100 oz Silver Bars",bread:'/Silver/Silver-Bars/100-oz-Silver-Bars',image:'/static/images/100 oz Silver Bars (1).png'},
    {name:"Kilo Silver Bars",bread:'/Silver/Silver-Bars/Kilo-Silver-Bars',image:'/static/images/Kilo Silver Bars (1).png'},
    {name:"Other Silver Bars",bread:'/Silver/Silver-Bars/Other-Silver-Bars',image:'/static/images/Other Silver Bars (1).png'},
    ]
    let goldcoincats= [{name:"U.S. Gold Coins",bread:'/Gold/Gold-Coins/U.S.-Gold-Coins',image:'/static/images/US Gold Coins.png'}, 
    {name:"Australian Gold Coins",bread:'/Gold/Gold-Coins/Australian-Gold-Coins'}, 
    {name:"Austrian Gold Coins",bread:'/Gold/Gold-Coins/Austrian-Gold-Coins',image:'/static/images/Austrian Gold Coins (1).png'}, 
    {name:"British Gold Coins",bread:'/Gold/Gold-Coins/British-Gold-Coins'},{name:"Canadian Gold Coins",bread:'/Gold/Gold-Coins/Canadian-Gold-Coins',image:'/static/images/Canadian Gold Coins (1).png'},{name:"Chinese Gold Coins",bread:'/Gold/Gold-Coins/Chinese-Gold-Coins',image:'/static/images/Chinese Gold Coins (1).png'},{name:"Mexican Gold Coins",bread:'/Gold/Gold-Coins/Mexican-Gold-Coins',image:'/static/images/Mexican Gold Coins (1).png'},{name:"African Gold Coins",bread:'/Gold/Gold-Coins/African-Gold-Coins',image:'/static/images/African Gold Coins (1).png'},{name:"Other Gold Coins",bread:'/Gold/Gold-Coins/Other-Gold-Coins'}]
    
    let goldbarcats =[
    {name:"1 oz Gold Bars",bread:'/Gold/Gold-Bars/1-oz-Gold-Bars',image:'/static/images/1 oz Gold Bars (1).png'},
    {name:"5 oz Gold Bars",bread:'/Gold/Gold-Bars/5-oz-Gold-Bars'},
    {name:"10 oz Gold Bars",bread:'/Gold/Gold-Bars/10-oz-Gold-Bars'},
    {name:"Fractional Gold Bars",bread:'/Gold/Gold-Bars/Fractional-Gold-Bars',image:'/static/images/Fractional Gold Bars (1).png'}]
    let platcats = [
    {name:"Platinum Coins",bread:'/Platinum/Platinum-Coins',image:'/static/images/Plat Coins.png'},
    {name:"Platinum Bars",bread:'/Platinum/Platinum-Bars',image:'/static/images/Plat Bars.png'},]
    if(isLoadingSearch){

    }else {

    const structuredData = {
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "url": "https://www.example.com/product/1",
              "item": {
                "@type": "Product",
                "name": "Example Product 1",
                "image": "https://www.example.com/product1.jpg",
                "description": "Description of Example Product 1",
                "sku": "12345",
                "brand": {
                  "@type": "Brand",
                  "name": "Brand Name"
                },
                "offers": {
                  "@type": "Offer",
                  "url": "https://www.example.com/product/1",
                  "priceCurrency": "USD",
                  "price": "19.99",
                  "itemCondition": "https://schema.org/NewCondition",
                  "availability": "https://schema.org/InStock"
                }
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "url": "https://www.example.com/product/2",
              "item": {
                "@type": "Product",
                "name": "Example Product 2",
                "image": "https://www.example.com/product2.jpg",
                "description": "Description of Example Product 2",
                "sku": "12346",
                "brand": {
                  "@type": "Brand",
                  "name": "Brand Name"
                },
                "offers": {
                  "@type": "Offer",
                  "url": "https://www.example.com/product/2",
                  "priceCurrency": "USD",
                  "price": "29.99",
                  "itemCondition": "https://schema.org/NewCondition",
                  "availability": "https://schema.org/InStock"
                }
              }
            }
            // Add more products as needed
          ]
        };      


    return(
        <div className='minH'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{pathParams[0]}</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content={pathParams[0]}/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
            <script type="application/ld+json">
                {JSON.stringify(structuredData)}
              </script>
        </Helmet>
        <div style={{color:'black', marginBottom:30,textAlign:'center',padding:10,fontSize:30,marginLeft:'5%',marginRight:'5%',textShadow:'1px 1px white'}} className={headerBackgroundStyle}>
            Recommended Items
        </div>

            <div className='fb jsb recommendCont mobileWrap' id="areas">
            {itemList != undefined && itemList.map((item,index)=>{
                return(
                    <PLPCard key={index} item={item}/>
            )})}
            {itemList != undefined && 
                itemList.length == 0
                    &&
                    <div style={{margin:'150px 0px',textAlign:'center',width:'100%',background:'transparent',fontWeight:'bolder'}}>
                    No Results
                    </div>
            }
            </div>
        </div>
    )

          }
}