import * as React from 'react';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../config.tsx';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

export default function CreateAccount() {

    const [emailValue, setEmailValue] = React.useState("");
    const [passValue, setPassValue] = React.useState("");
    const [passVerValue, setPassVerValue] = React.useState("");
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const nav = useNavigate()
    function hasSpecialOrNumber(s) {
      var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]/;
      return regex.test(s);
  }

    async function createNewAccount(){
      if(passValue != passVerValue){
        alert("Password values must match.")
        return
      }
      if(hasSpecialOrNumber(passValue)){
        createUserWithEmailAndPassword(auth, emailValue, passValue)
        .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            alert("NEW USER!!!")
            nav("/myaccount")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage)
            // ..
        });
      }else{
        alert("Password must contain special charater or number.")
      }
    }

  return (

    <div className='minH2'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Create Account</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Create Account Page"/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
        </Helmet>
    <h1 className='tac'>Customer Sign Up</h1>
    <div className='fb fw hmarg40'>
        <div className='h50Box mb60'>
            <div><b>Create New Account</b></div>
            <div>If you have an account, sign in with your email address.</div>
            <div>
                <div style={{marginLeft:"-10px"}}>
                <FormControl fullWidth sx={{ m: 1 }} >
                    <InputLabel htmlFor="outlined-adornment-amount">Email *</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        label="Email"
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)}
                    />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount2">Password *</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount1"
                        label="Password"
                        onChange={(e) => setPassValue(e.target.value)}
                        type={showPassword1 ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() => setShowPassword1(!showPassword1)}
                            >
                              {showPassword1 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                    />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-amount2">Password Verify *</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount2"
                        label="Password Verify"
                        onChange={(e) => setPassVerValue(e.target.value)}
                        type={showPassword2 ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="start"
                              onClick={() => setShowPassword2(!showPassword2)}
                            >
                              {showPassword2 ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                    />
                    </FormControl>
                    <hr/>
                    <div className='dmarg fb je fw'>
                        <Button variant="contained" onClick={()=>{createNewAccount()}}>Create Account</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}