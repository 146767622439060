import * as React from 'react';
import { useState } from 'react';
import { Box, Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { createUserWithEmailAndPassword, getAuth, signOut, updateEmail } from "firebase/auth";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { UserContext } from '../../providers/UserProvider.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { doc, updateDoc ,getDoc} from "firebase/firestore";
import { auth, db } from '../../config.tsx';
import PhoneIcon from '@mui/icons-material/Phone';
import {Helmet} from "react-helmet";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function UserPage() {
    let nav = useNavigate();
    const functions = getFunctions();
    let authAccess = auth;
    const [name, setName] = React.useState("");
    const [addr1, setAddr1] = React.useState("");
    const [addr2, setAddr2] = React.useState("");
    const [addrCity, setAddrCity] = React.useState("");
    const [addrState, setAddrState] = React.useState("");
    const [addrZip, setAddrZip] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
	const user = React.useContext(UserContext);
    const [emailValue, setEmailValue] = React.useState("None");
    const [error1, setError1] = React.useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [error5, setError5] = useState(false);
    const [error6, setError6] = useState(false);
    const [error7, setError7] = useState(false);
    const [error8, setError8] = useState(false);
    const [dataloading, setdataloading] = React.useState(true);

    const [showAccountID, setShowAccountID] = useState(false);
    const [addressUpdated, setAddressUpdated] = React.useState(false);
    const [addrEmail, setAddrEmail] = React.useState("");
    const [addrPhone, setAddrPhone] = React.useState("");
    const handleInputChange = (event, fieldSetter, errorSetter) => {
        const value = event.target.value;
        if(value != ''){
            setAddressUpdated(true);
        }
        fieldSetter(value);
    
        // Check if the input is empty and set the error state accordingly
        errorSetter(value.trim() === '');
      };
      const formatPhoneNumber = (input) => {
        // Replace all non-digit characters with an empty string
        const cleaned = input.replace(/\D/g, '');
    
        // Apply phone number formatting
        if (cleaned.length <= 3) {
          return cleaned;
        } else if (cleaned.length <= 7) {
          return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
        } else {
          return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
        }
      };
      const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if(value != ''){
            setAddressUpdated(true);
        }
        const formattedNumber = formatPhoneNumber(e.target.value);
        setAddrPhone(formattedNumber);
      };

    async function updateUserEmail(email){
        updateEmail(authAccess.currentUser, addrEmail).then(() => {
          // Email updated!
          // ...
          alert("Email Updated")
        }).catch((error) => {
          // An error occurred
          // ...
          console.log(error)
          alert("errro email ")
        });
    }

    async function commitAddressChanges(){
        if(addressUpdated && user){
            var addrObj = {
                addrline1: addr1,
                addrline2: addr2,
                addrcity: addrCity,
                addrstate: stateInputVal,
                addrzip: addrZip,
                name:name,
                email:addrEmail,
                phone:addrPhone
            }
            console.log(addrObj)
            const docRef = doc(db, "users", user.uid);

            // Set the "capital" field of the city 'DC'
            await updateDoc(docRef, addrObj);

            //await updateUserEmail(emailValue)

            alert("Address Updated!")
        }else{
            alert("no updates added.")
        }
    }
    async function fetchUserAddress(userUID) {
        const docRef = doc(db, "users", userUID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            if(docSnap.data().addrline1){
                setAddr1(docSnap.data().addrline1)
            }
            if(docSnap.data().addrline2){
                setAddr2(docSnap.data().addrline2)
            }
            if(docSnap.data().addrcity){
                setAddrCity(docSnap.data().addrcity)
            }
            if(docSnap.data().addrstate){
                setStateInputValue(docSnap.data().addrstate)
            }
            if(docSnap.data().addrzip){
                setAddrZip(docSnap.data().addrzip)
            }
            if(docSnap.data().name){
                setName(docSnap.data().name)
            }
            if(docSnap.data().email){
                setAddrEmail(docSnap.data().email)
            }
            if(docSnap.data().phone){
                setAddrPhone(docSnap.data().phone)
            }
        } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
    }
    setdataloading(false)
}
React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
function signOutUser(){
    const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
        //alert("you have been signed out.")
        nav("/login")
        }).catch((error) => {
        // An error happened.
        alert(error)
        });
}
async function removeAccountcall(uid){
    const addMessage = await httpsCallable(functions, 'deleteUser');
    addMessage({id:uid})
    .then((result) => {

    })
}
async function userDeleted(){
    var response = window.confirm("Do you want to proceed with account deletion?");
    if (response == true) {
    let userId = user.uid
    const auth = getAuth();
        signOut(auth).then(() => {
            removeAccountcall(userId)
        // Sign-out successful.
        //alert("you have been signed out.")
        alert("Account has been removed.")
        nav("/login")

        
        }).catch((error) => {
        // An error happened.
        alert(error)
        })
    }

}

const [stateInputVal, setStateInputValue] = React.useState('');

const handleChange = (event: any) => {
    setStateInputValue(event.target.value as string);
};

React.useEffect(() => {
		if (user) {
            setEmailValue(user.email)
            fetchUserAddress(user.uid);
            setIsLoading(false)
        }else{
            nav("/login")
        }
    },[user])

    if(isLoading){
        return(
            <div className='fb jc minH ac'>
            <CircularProgress />
          </div>
        )
    }else{
  return (

    <div className='mainpageblock minH' style={{marginTop:80}}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Account</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="About Page"/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
        </Helmet>
    <h1 className='tac'>My Account</h1>
    <div className='fb fw'>
        <div className='mb60 w100 hmarg40'>
            <div className='fb jsb'>
            <div style={{paddingTop:5}}>
                <b>Account Email : {emailValue}</b>
            </div>
            </div>
            <hr/>
            <div className='fb jsb'>
                        <Link to="/myorders">
                            <Button variant="contained">My Orders</Button>
                        </Link>
                        <Button variant="contained" onClick={()=>{signOutUser()}}>Sign Out</Button>

            </div>
            <hr/>
            <div><b>Address Info (Saved for checkout process)</b></div>
            <br/>
            <div className='fb fw'>
                 <div className='cardcontain2'>
                 <div style={{textAlign:'center'}}>
                        {dataloading && (<CircularProgress />)}
                    </div>
                    <div className='fb fdc'>
                    <TextField id="outlined-basic" label="Name" variant="outlined" 
                            focused={true}
                            value={name} 
                            onInput={(e) => {
                                handleInputChange(e,setName,setError6)
                            }}
                            error={error6}
                            helperText={error6 ? 'This field is required' : ''}
                            /><br/>
                <TextField id="outlined-basic" label="Address Line 1" variant="outlined" 
                            focused={true}
                            value={addr1} 
                            onInput={(e) => {
                                handleInputChange(e,setAddr1,setError1)
                            }}
                            error={error1}
                            helperText={error1 ? 'This field is required' : ''}
                            /><br/>
                        <TextField id="outlined-basic" label="Address Line 2" variant="outlined" 
                        value={addr2} 
                        onInput={(e) => {
                            handleInputChange(e,setAddr2,setError2)
                        }}
                        /><br/>
                        <TextField id="outlined-basic" label="City" variant="outlined"
                        value={addrCity} 
                        onChange={(e) => {
                            handleInputChange(e,setAddrCity,setError3)
                        }}
                        error={error3}
                        helperText={error3 ? 'This field is required' : ''}
                        /><br/>
                                                <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">State *</InputLabel>
                                <Select
                                id="demo-simple-select"
                                value={stateInputVal}
                                onChange={handleChange}
                                label="State *"
                                >
                                <MenuItem value={"AR"}>Arkansas</MenuItem>
<MenuItem value={"CA"}>California</MenuItem>
<MenuItem value={"GA"}>Georgia</MenuItem>
<MenuItem value={"IN"}>Indiana</MenuItem>
<MenuItem value={"IA"}>Iowa</MenuItem>
<MenuItem value={"KS"}>Kansas</MenuItem>
<MenuItem value={"KY"}>Kentucky</MenuItem>
<MenuItem value={"MI"}>Michigan</MenuItem>
<MenuItem value={"MN"}>Minnesota</MenuItem>
<MenuItem value={"NE"}>Nebraska</MenuItem>
<MenuItem value={"NV"}>Nevada</MenuItem>
<MenuItem value={"NJ"}>New Jersey</MenuItem>
<MenuItem value={"NC"}>North Carolina</MenuItem>
<MenuItem value={"ND"}>North Dakota</MenuItem>
<MenuItem value={"OH"}>Ohio</MenuItem>
<MenuItem value={"OK"}>Oklahoma</MenuItem>
<MenuItem value={"PA"}>Pennsylvania</MenuItem>
<MenuItem value={"RI"}>Rhode Island</MenuItem>
<MenuItem value={"SD"}>South Dakota</MenuItem>
<MenuItem value={"TN"}>Tennessee</MenuItem>
<MenuItem value={"UT"}>Utah</MenuItem>
<MenuItem value={"VT"}>Vermont</MenuItem>
<MenuItem value={"WA"}>Washington</MenuItem>
<MenuItem value={"WV"}>West Virginia</MenuItem>
<MenuItem value={"WI"}>Wisconsin</MenuItem>
<MenuItem value={"WY"}>Wyoming</MenuItem>
                                </Select>
                            </FormControl>
                            </Box>
                        <br/>
                        <TextField id="outlined-basic" label="Zipcode" variant="outlined"
                        value={addrZip} 
                        onChange={(e) => {
                            handleInputChange(e,setAddrZip,setError5)
                        }}
                        error={error5}
                        helperText={error5 ? 'This field is required' : ''}
                        /><br/>
                        <TextField id="outlined-basic" label="Email" variant="outlined"
                        value={addrEmail} 
                        onChange={(e) => {
                            handleInputChange(e,setAddrEmail,setError7)
                        }}
                        error={error7}
                        helperText={error7 ? 'This field is required*' : ''}
                        /><br/>
                        <TextField id="outlined-basic" label="Phone Number" variant="outlined" type=''
                        value={addrPhone} 
                        onChange={handlePhoneNumberChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={error8}
                        helperText={error8 ? 'This field is required' : ''}
                        /><br/>
                    <hr/>
                    <div className='dmarg fb je fw'>
                        <Button variant="contained" onClick={()=>{commitAddressChanges()}}>Save Info</Button>
                    </div>
                    <hr/>
                    <div className='dmarg fb je fw'>
                        <Button variant="contained" onClick={()=>{userDeleted()}}>Delete Account</Button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
  );
                    }
}